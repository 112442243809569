.review-item {
  margin-top: 0;
  
  margin-bottom: 2rem;
}

.review-item__content {
  width: 20rem;
}

.review-item__info .star-icon {
  margin: 0.5rem;

  color: var(--secondary-color);

  text-align: center;

  font-size: 1rem;
}

/* .review-item__image img {

  float: left;

  border: 1px solid var(--primary-color);

  margin: 5px 10px 10px 0px;

  height: 140px;

  width: 140px;

  padding: 0;

} */

/* 

.review-item__image {

  padding: 0;

  color: black;

}
 */

.review-item__image span {
  font-family: var(--third-font);

  font-size: 1.2rem;

  color: red;
}

.review-item__image .link-menu__detail {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .review-item__content {
    width: 50rem;
  }
}
