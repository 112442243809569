.subheader {
  background: var(--secondary-color);

  color: #fff;

  box-shadow: 3px 3px 10px #888888;

  font-size: 14px;

  padding: 10px;

  width: 90%;

  margin: 0.5rem auto 1rem;

  text-align: center;
}

a {
  text-decoration: none;
}

.grid {
  display: grid;

  grid-template-columns: repeat(2, 1fr);

  grid-gap: 10px;

  cursor: pointer;

  justify-content: center;
}

.grid .item {
  display: flex;
  flex-direction: column;
  border: 1px #ccc solid;
  padding: 10px;
  box-shadow: 1px 1px 2px #ccc;
}

.grid .item:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease-in-out;
}

.grid .item{
  margin-bottom: 5px;
  text-align: center;
  color: var(--primary-color);
  font-size: 18px;
}

h4{
  color: #326469;
}

.item-h4-container {
  height: 56px;
}

.grid .item p {
  font-size: 14px;

  color: var(--secondary-color);

  font-weight: bold;

  margin-bottom: 20px;
}

.grid .item img {
  height: 80px;

  width: 120px;

  border-radius: 5px;

  text-align: center;

  display: block;

  align-self: center;
}

.input__search {
  font-size: 24px;
  border-radius: 10px;
  border: 1px solid gray;
  padding-left: 10px;
  color: rgb(78, 75, 75);
  width: 240px;
 
}

.input__search:focus {
  outline: none;
  border-color: var(--primary-color);
}

.span__search {
  margin-left: 4px;
  font-size: 24px;
}
