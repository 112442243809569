a {
  color: var(--primary-color);
}

.user-signup-container {
  margin-top: 3.5rem;
}

.btn {
  margin-top: 10px;
}
.modal__footer {
  padding: 0;
}
