.main-header {

  background: var(--primary-color);

  display: flex;

  flex-direction: row;

  align-items: center;

  justify-content: space-between;

  padding: 10px 10px;

}

