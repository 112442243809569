.addmenu-container {
  margin: 1rem;

  text-align: center;

  margin-bottom: 5rem;

  padding: 0 0 50px;
}

.addmenu-container .btn-add {
  background-color: var(--primary-color) !important;

  margin-bottom: 0.5rem;

  display: block;

  width: 100%;
}

.addmenu-container .btn-edit {
  background-color: rgb(14, 14, 196);

  border: none;
}

.addmenu-container .btn-delete {
  background-color: rgba(236, 63, 63, 0.945);

  border: none;
}

.addmenu-container .btn-add:hover,
.btn-edit:hover,
.btn-delete:hover {
  background-color: lightgray !important;

  border-color: lightgray;
}

.addmenu-container .btn-actions {
  display: flex;

  justify-content: center;

}

.btn-actions {
  padding-right: 30px;
  
}
/*
Max width before this PARTICULAR table gets nasty

    This query will take effect for any screen smaller than 760px

    and also iPads specifically.

    */

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */

  .addmenu-container table,
  .addmenu-container thead,
  .addmenu-container tbody,
  .addmenu-container th,
  .addmenu-container td,
  .addmenu-container tr {
    display: block;
  }

  .addmenu-container td.btn-delete {
    text-align: left;
  }

  /* Hide table headers (but not display: none;, for accessibility) */

  .addmenu-container thead tr {
    position: absolute;

    top: -9999px;

    left: -9999px;
  }

  .addmenu-container tr {
    border: 1px solid #ccc;
  }

  .addmenu-container td {
    /* Behave  like a "row" */

    border: none;

    border-bottom: 1px solid #eee;

    position: relative;

    padding-left: 50%;

    word-wrap: break-word;
  }

  .addmenu-container td:before {
    /* Now like a table header */

    position: absolute;

    /* Top/left values mimic padding */

    top: 6px;

    left: 6px;

    width: 45%;

    padding-right: 10px;

    white-space: nowrap;

    word-wrap: break-word;
  }

  /*Label the data*/

  .addmenu-container td:nth-of-type(1):before {
    content: "Data";
  }

  .addmenu-container td:nth-of-type(2):before {
    content: "Nume";
  }

  .addmenu-container td:nth-of-type(3):before {
    content: "Categoria";
  }

  .addmenu-container td:nth-of-type(4):before {
    content: "SubCategoria";
  }

  .addmenu-container td:nth-of-type(5):before {
    content: "Pret";
  }

  .addmenu-container td:nth-of-type(6):before {
    content: "Pret Vechi";
  }

  .addmenu-container td:nth-of-type(7):before {
    content: "Culoare";
  }

  .addmenu-container td:nth-of-type(8):before {
    content: "Dimensiuni";
  }

  .addmenu-container td:nth-of-type(9):before {
    content: "Brand";
  }

  .addmenu-container td:nth-of-type(10):before {
    content: "Material";
  }

  .addmenu-container td:nth-of-type(11):before {
    content: "Cantitate";
  }

  .addmenu-container td:nth-of-type(12):before {
    content: "Descriere";
  }
}
