.history-container {
  margin: 1rem;

  text-align: center;
}

.history-container .btn-actions {
  display: flex;

  justify-content: center;
}

.btn-delete {
  background-color: rgba(236, 63, 63, 0.945);

  border: none;
}

.btn-delete:hover {
  background-color: lightgray;

  border: none;
}

/* 

Max width before this PARTICULAR table gets nasty

This query will take effect for any screen smaller than 760px

and also iPads specifically.

*/

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .history-container .btn-actions {
    display: flex;

    justify-content: left;
  }

  /* Force table to not be like tables anymore */

  .history-container table,
  .history-container thead,
  .history-container tbody,
  .history-container th,
  .history-container td,
  .history-container tr {
    display: block;    
  }

  table{
    margin-bottom: 80px;
  }

  .history-container td.btn-delete {
    text-align: left;
  }

  /* Hide table headers (but not display: none;, for accessibility) */

  .history-container thead tr {
    position: absolute;

    top: -9999px;

    left: -9999px;
  }

  .history-container tr {
    border: 1px solid #ccc;
  }

  .history-container td {
    /* Behave  like a "row" */

    border: none;

    border-bottom: 1px solid #eee;

    position: relative;

    padding-left: 50%;
  }

  .display-menu-actions__modal {
    margin-top: 50px;
  }

  .history-container td:before {
    /* Now like a table header */

    position: absolute;

    /* Top/left values mimic padding */

    top: 6px;

    left: 6px;

    width: 45%;

    padding-right: 10px;

    white-space: nowrap;
  }

  /*

	Label the data

	*/

  .history-container td:nth-of-type(1):before {
    content: "Dată comandă:";
  }

  .history-container td:nth-of-type(2):before {
    content: "Cantitate:";
  }

  .history-container td:nth-of-type(3):before {
    content: "Suma platită:";
  }

  .history-container td:nth-of-type(4):before {
    content: "Nume produs:";
  }
 
}
