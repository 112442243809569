.main-footer {
  flex-shrink: 0;
  background: var(--primary-color);
  padding: 5px;
  font-size: 14px;
  margin-top: 10px;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
}
.main-footer a {
  color: white;
}
