.about {
  margin-top: 2rem;
  margin-bottom: 6rem;
}

.about-hours {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item {
  font-family: var(--third-font);
  font-size: 20px;
  color: black;
  width: 100%;
}

.item-box {
  background-color: var(--secondary-color);
  background-size: cover;
  position: relative;
  border-color: gray;
  border-style: ridge;
  margin: 10px;
}

.about-list {
  list-style: none;
  margin: 0.5rem auto;
  padding: 0;
  width: 90%;
}

.about-item__info {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
}

.about-item__info .about-day {
  color: #fff;
  white-space: nowrap;
}

.about-item__info .about-time {
  color: black;
  white-space: nowrap;
}

.dots {
  border-bottom: 1px black dotted;
}

.map-container {
  height: 20rem;
  width: 100%;
}

@media (min-width: 768px) {
  .about {
    margin-top: 4rem;
  }
}
