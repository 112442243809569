/* .menu-selected {
  margin: 1rem 0;

} */
.menu-selected__content {
  padding: 0;
  width: 100%;
  max-width: 40rem;
  padding-bottom: 60px;
}

.menu-selected__allinfo {
  margin: 0px 10px 0px 10px;
}

.menu-selected__info {
  padding: 1rem;
  text-align: center;
}

.menu-selected__itemName{
  font-family: var(--third-font);
  font-size:24px;
}

/* --primary-font: 'Rancho', cursive;

--secondary-font: 'Concert One', cursive;

--third-font: 'Nunito', sans-serif;

--fourth-font: 'Dancing Script', cursive;

--fifth-font: 'Gotu', cursive; */

.menu-selected__itemDesc{
  font-family: var(--third-font);  
  font-size:18px;  
}

.menu-selected__price {
  padding: 0px 1rem 0px 1rem;
}

.menu-selected__priceold {
  padding: 0px 1rem 0.4rem 1rem;
}

.menu-selected__priceearning {
  padding: 0px 1rem 0rem 1rem;
}

.menu-selected__tva {
  padding: 0px 1rem 0.4rem 1rem;
}

.menu-price {
  color: rgb(29, 29, 29);
}

.menu-price-old {
  font-size: 20px;
  text-decoration: line-through;
}

.menu-price-earning {
  color: rgb(0, 126, 67);
}

.menu-selected__image {
  width: 100%;
  margin-right: 1.5rem;
  background-color: rgb(238, 238, 238);
}

.menu-selected__image img {
  width: 100%;
  height: 340px;
  object-fit: cover;
}

.menu-selected__info h2,
.menu-selected__info h3,
.menu-selected__info p {
  margin: 0 0 0.5rem 0;
}

.menu-selected__info p {
  text-align: justify;
}

.menu-selected__actions {
}



.cnt-btn-back-to-list {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  height: 40px;
}

.cnt-btn-add-to-cart {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  height: 40px;
}

.cnt-btn-write-review {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  height: 40px;
}

.btn-add-to-cart {
  background-color: Transparent;
  border: 1px solid rgb(167, 108, 0);
  color: black;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  width: 90%;  
  border-radius: 30px;
  font-size: 16px;
  margin-right: 0;
  height: 40px;
}

.btn-write-review {
  background-color: Transparent;
  border: 1px solid rgb(167, 108, 0);
  color: black;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  width: 90%;
  border-radius: 30px;
  font-size: 16px;
  margin-right: 0;
  height: 40px;
}

.btn-back-to-list {
  background-color: Transparent;
  border: 1px solid rgb(167, 108, 0);
  color: black;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  width: 90%;
  border-radius: 30px;
  font-size: 16px;
  margin-right: 0;
  height: 40px;
}

.add-to-cart {
  font-size: 22px;
  vertical-align: top;
}
.add-to-cart-text{  
  padding-left: 5px;
  vertical-align: top;
}

.write-review {
  font-size: 22px;
  vertical-align: top;
}
.write-review-text{
  padding-left: 5px;
  vertical-align: top;
}

.back-to-list {
  font-size: 24px;
  vertical-align: top;
}
.back-to-list-text{
  font-size: 17px;
  padding-left: 5px;
  vertical-align: top;
}

.menu-selected__modal-content {
  padding: 1rem;
}

.menu-selected__modal-actions {
  text-align: right;
}

.menu-selected__itemSpecContainer{
  background-color: rgb(182, 196, 199);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.menu-selected__itemSpecHeader{
  font-family: var(--third-font); 
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  align-self:center;

}

.menu-selected__itemSpecContent{ 
  font-family: var(--third-font); 
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size:18px;
}

.menu-selected__itemSpec{
  display: flex;
}

.menu-selected__itemSpecHead{
  font-weight: bold;
  margin-right: 8px;
}

.menu-selected__reviews {
  margin-top: 30px;
  width: 100%;
  background-color: rgb(238, 238, 238); 
  position: relative;
  margin-bottom: 50px; 
  min-height: 170px; 
}

.cnt-rev {
  width: 50%;
  float: left;
  margin-bottom: 30px;
}

.reviews-header {
    padding-top: 10px;  
}

.reviews-left {
  padding-left: 40px;
  font-size: 20px;
}

.reviews-no-found{
  text-align: center;
}

.review-avg-rating {
  color: rgb(0, 32, 63);
  font-size: 20px;
  float: left;
}

.reviews-nr-users {
  width: 20%;
  float: left;
  font-size: 20px;
}

.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: center;
  border: 0;
}

.rating button {
  font-size: 1.5rem;
  background: transparent;
  border: 0;
  outline: none;
}

.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
}

.rating > button:hover:before,
.rating > button:hover ~ button:before {
  content: "\2605";
  position: absolute;
  color: var(--primary-color);
}

.review-buttons{

  display: flex;
  justify-content: center;
}
@media(max-width: 390px){
  .review-buttons .button{
    padding: 0.5rem 1rem;
  }
}

#ratingText {
  margin-bottom: 0.5rem;
  background-color: transparent;
  border: none;
  color: red;
  font-size: 13.5px;
}

.menu-selected__rating {
}

.menu-avg-rating {
  color: rgb(0, 32, 63); 
  margin-top: 10px;  
  margin-bottom: 10px;
  margin-left:12px;
  float: left;
}

.reviews-nr-users-top{
  float: left;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select-rating__input {
  text-align: center;
}

@media (min-width: 768px) {
  .menu-selected__image {
    height: 20rem;
  }
}


