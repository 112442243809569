.app-header a {
  color: white;
  font-family: var(--third-font);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spanImg{
  position: relative;
  top: -18px;
  right: 10px;
  font-size: 18px;
  font-weight: bold;
}

