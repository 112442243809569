/* .item-box {

  background-color: var(--secondary-color);

  background-size: cover;

  position: relative;

  border-color: gray;

  border-style: ridge;

  margin-bottom: 10px;

} */

.header {
  text-align: center;

  /*font-family: var(--primary-font);*/

  /*padding: 0.2rem;*/

  font-size: 18px;

  color: black;
}

.menu-list {
  list-style: none;

  margin: auto;

  margin-bottom: 70px;

  padding: 0;

  width: 100%;
}

.not-found {
  margin-top: 10rem;

  text-align: center;
}

.buffet-type {
  text-align: center;
}

.subcateg__container {
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  padding-top: 5px !important;
}

.subcateg__li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 18px;
  list-style-type: none;
  height: 50px;
  background-color: rgb(108, 193, 214);
  border-bottom: 1px white solid;
  color: rgb(255, 255, 255);
  font-size: 20px;
  line-height: 20px;
}

.subcateg__input {
  zoom: 1.5;
  margin-right: 10px;
  color: green;
}

.subcateg__select {
  display: flex;
  justify-content: center;
  align-items: center;  
  background-color: rgb(36, 157, 187);
  border-bottom: 1px white solid;
  color: rgb(255, 255, 255);
  height: 35px;
  font-size: 20px;
  vertical-align: bottom;
}

.hidden {
  display: none;
}

.subcateg__span {  
  margin-left: 10px;
}

/* @media (min-width: 768px) {

  .item-box {

    display: grid;

    grid-template-columns: repeat(3, 1fr);

    grid-gap: 5px;

    justify-items: center;

  }

} */
