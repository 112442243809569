.order-list {
  list-style: none;

  margin: 1rem auto;

  padding: 0;

  width: 100%;

  display: flex;

  justify-content: center;

  flex-wrap: wrap;
}

.order-item {
  margin-top: 0;

  margin-bottom: 1rem;

  width: 100%;

  display: flex;

  justify-content: center;

  flex-wrap: wrap;
}

.order-item__content {
  width: 90%;
}

.card-container {
  display: flex;

  flex-direction: row;

  height: auto;
}

.order-item__quantity_selector_container {
  display: flex;

  flex-direction: column;

  border: rgb(105, 147, 224) solid 1px;

  border-radius: 10%;

  height: 80px;

  width: 50px;

  padding: 0;

  margin: 0;

  overflow: hidden;
}

.increment-quantity {
  padding-top: 1px;

  text-align: center;

  color: rgb(218, 27, 27);

  font-size: 20px;
}

.quantity {
  text-align: center;

  font-size: 20px;
}

.decrement-quantity {
  text-align: center;

  color: rgb(218, 27, 27);

  font-size: 20px;
}

/* .order-item__image img {
  border: 1px solid var(--primary-color);

  border-radius: 10px;

  margin-left: 10px;

  height: 80px;

  width: 50px;

  padding: 0;
} */

.order-item__name {
  
  margin-left: 20px;

  display: inline-block;

  word-break: break-word;

  width: 200px;
}

.card-container .order-item__price {
  font-size: 18px;

  color: rgb(23, 121, 167);
}

.card-container .order-item__delete {
  margin-left: 30px;
  color: rgb(218, 27, 27); 
}

.cart-total {
  margin-left: 20px;

  margin-right: 20px;

  display: flex;

  flex-direction: row;

  justify-content: space-between;

  font-size: 20px;
}

.cart-tax {
  margin-top: 0;

  margin-left: 20px;

  margin-right: 20px;

  display: flex;

  flex-direction: row;

  justify-content: space-between;

  font-size: 20px;
}

.cart-sub-total {
  margin-top: 0;

  margin-left: 20px;

  margin-right: 20px;

  display: flex;

  flex-direction: row;

  justify-content: space-between;

  font-size: 20px;

  color: rgb(218, 27, 27);
}
 .cart-footer {
   padding: 0 0 20px;
 }

.cart-footer .cart-button {
  text-align: center;

  margin-bottom: 70px;
}

.cart-footer .cart-button button {
  font-size: 20px;

  margin: 10px;

  width: 90%;
}

.cart-order-text{
  margin-top:25px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 5px;  
}

.cart__label--nrtel{
text-align: center;
  font-size: 19px;
  color:red;
}

.cart__input--nrtel{
  text-align: center;
  font-size: 24px;
  color:red;
  border:1px red solid;
  border-radius: 3px;
}
.cart__input--nrtel:focus{
  outline: none;
}
.cart__container--nrtel{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .order-item__content {
    width: 50%;
  }

  .cart-footer .cart-button button {
    width: 50%;
  }

  .cart-total {
    margin-left: 375px;

    margin-right: 375px;
  }

  .cart-tax {
    margin-left: 375px;

    margin-right: 375px;
  }

  .cart-sub-total {
    margin-left: 375px;

    margin-right: 375px;
  }
}
