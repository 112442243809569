.main-footer ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.main-footer ul a {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-footer ul li {
  font-size: 16px;
  list-style: none;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.3rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: var(--secondary-color);
  /*border-color: #ffffff;*/
  color: #423b63;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #423b63;
  color: white;
}
