.card {

  position: relative;

  margin: 0;

  box-shadow: 0 2px 3px gray;

  border-radius: 6px;

  padding: 1rem;

  overflow: hidden;

  background: white;

}

