.menu-form {

  margin: 1rem auto;



  list-style: none;



  padding: 0 1rem 80px;

  width: 90%;

  max-width: 40rem;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  border-radius: 6px;

  background: white;

}

.item {

  text-align: center;

}



fieldset {

  justify-content: center;

  min-width: 100%;

}



.item label {

  font-size: 1rem;

  margin: 0.7rem;

  padding: 0;

}



.update-menu__actions {

  display: flex;

  justify-content: center;

}



select.select-available {

  font-size: 1rem;

}



#name,

#description,

#price {

  font-size: 1rem;

}



.available {

  margin-top: 0.5rem;

}

