/* @import url("https://fonts.googleapis.com/css?family=Nunito&display=swap"); */

/* @import url("https://fonts.googleapis.com/css?family=Concert+One&display=swap");

@import url("https://fonts.googleapis.com/css?family=Rancho&display=swap");

@import url("https://fonts.googleapis.com/css?family=Dancing+Script&display=swap"); */

:root {
  --primary-color: #069dab;

  --secondary-color: #0cc6d2;

  --hover-color: #68eaf3;

  --border-color: #4feaf5;

  --primary-font: "Nunito", sans-serif;

  --secondary-font: "Nunito", sans-serif;

  --third-font: "Nunito", sans-serif;

  --fourth-font: "Nunito", sans-serif;

  --fifth-font: "Nunito", sans-serif;
}

html,
body {
  height: 100%;
}

* {
  box-sizing: border-box;

  margin: 0;

  padding: 0;
}

body {
  font-family: var(--third-font);
}

.menu-selected__info {
  font-family: var(--fifth-font);
}

/* Push footer at the bottom */

.content {
  min-height: calc(100vh - 145px);
  padding-bottom: 10px;
}

/* Text Styles*/

.x-large {
  font-size: 4rem;

  line-height: 1.2;

  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;

  line-height: 1.2;

  margin-bottom: 1rem;
}

.medium {
  font-size: 1.5rem;

  line-height: 1.2;

  margin-bottom: 1rem;
}

.small {
  font-size: 0.5rem;

  line-height: 1.2;

  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;

  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--secondary-color);
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

/* Padding */

.p {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

/* Margin */

.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.container {
  max-width: 1100px;

  margin: auto;

  overflow: hidden;

  padding-left: 1rem;

  padding-right: 1rem;

  padding-bottom: 60px;

  margin-bottom: 3rem;
}

.center {
  position: absolute;

  top: 50%;

  left: 50%;

  transform: translate(-50%, -50%);
}

/* CSSTransition Animation */

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);

  opacity: 1;

  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);

  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);

  opacity: 0;

  transition: all 200ms;
}

.slide-in-top {
  transform: translateY(-100%);

  transition: transform 1000ms ease;
}

.slide-in-top.slide-in-top-active {
  transform: translateY(0%);
}

.slide-in-bottom {
  transform: translateY(100%);

  transition: transform 1000ms ease;
}

.slide-in-bottom.slide-in-bottom-active {
  transform: translateY(0%);
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;

  transition: opacity 1s;

  z-index: -9999 !important;
}


@media (min-width: 635px) {
  body{
    width: 635px;
    margin:0 auto;
    background-color: rgba(255, 233, 198, 0.445);
  }
  .menu-selected__info {
    margin-top: 40px;
  }
  .main-footer{
    top:0;
    left:0;
  width: 400px;
  }
  .main-footer ul{
    display: flex;
    align-items: center;
    justify-content: left !important;
    padding-left:50px;
    
    
  }
  .main-footer{
    background-color: transparent !important;
  }

  .main-footer a{
    color: #069dab !important;
  }
  
}
