.product-review-item_content{    
    width: 100%; 
        
}
.product-review-item_top{
    width: 100%;
    
}
.user-data{
               
}
.user-image{    
    float:left;
    padding:2px;
    width: 50px;    
    height: 50px;
}

.image{    
    width: 42px;
    height: 42px;
    border-radius: 10px 50px 50px 50px;
    background-color: rgb(255, 255, 255);
    border:2px solid rgb(24, 117, 40);    
}

.user-name{
    font-size: 20px;
    padding-top:15px;
    padding-left:12px;
}

.date-review{
    padding-left:60px;
    padding-top:20px;
    width: 50%;
}

.fifty{     
    width: 50%;
    float:left;
}

.product-review-item_middle{    
    width: 100%;
    float:left;
}
.rating-stars{
    width: 100%;
    float:left;
}
.product-review-item_middle{
    margin-top:-5px;
    padding-left:62px;
}
.star-icon{
    font-size: 20px;;
}
.product-review-item_bottom{
    padding-left:62px;
    width: 100%;
    float:left;
    margin-bottom:10px;
}



