.bordered{  
  border: 1px gray solid;
}

.subcateg__container{
  padding:20px;
}

.menu-form {
  list-style: none;

  padding: 1rem;

  width: 90%;

  max-width: 40rem;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  border-radius: 6px;

  background: white;

  margin-bottom: 5rem !important;
}

.item {
  text-align: center;
}

.menu-take-photo {
  height: 200px;

  width: 200px;
}

fieldset {
  justify-content: center;

  min-width: 100%;
}

.item label {
  font-size: 1rem;

  margin: 0.2rem;

  padding: 0;

  display: inline-block;
}

.add-menu__actions {
  display: flex;

  justify-content: center;
}

select.select-available {
  font-size: 1rem;
}

#name,
#description,
#price {
  font-size: 1rem;
}

.available {
  margin-top: 0.5rem;
}
