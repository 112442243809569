.button {

  font: inherit;

  padding: 0.5rem 1.5rem;

  border: 1px solid var(--primary-color);

  border-radius: 4px;

  background: var(--secondary-color);

  color: white;

  cursor: pointer;

  margin-right: 0.5rem;

  text-decoration: none;

  display: inline-block;

  font-size: 0.7rem;

}



.button:focus {

  outline: none;

}



.button:hover,

.button:active {

  background: var(--hover-color);

  border-color: var(--border-color);

}



.button--inverse {

  background: transparent;

  color: #ff0055;

}



.button--inverse:hover,

.button--inverse:active {

  color: white;

  background: #ff0055;

}



.button--danger {

  background: #830000;

  border-color: #830000;

}



.button--danger:hover,

.button--danger:active {

  background: #f34343;

  border-color: #f34343;

}



.button:disabled,

.button:hover:disabled,

.button:active:disabled {

  background: #ccc;

  color: #979797;

  border-color: #ccc;

  cursor: not-allowed;

}



@media (min-width: 768px) {

  .button {

    font-size: 1rem;

  }

}

