.menu-item__content {
  margin: 0.5rem auto;

  padding: 0;

  width: 95%;

  max-width: 40rem;

  margin-bottom: 5px;

  display: flex;

  height: 100px;
}

.menu-item__text {
  margin: 8px;
  width: 70%;
}

.menu-item__image {
  width: 120px;
  height: 100px;
  overflow: hidden;
}

.menu-item__image img {
  width: 100px;

  height: 100px;
  /*  width: 170px; 
    height: 100px; 
    margin: 0 0 0 -50px; */
}

.price {
  padding-top: 15px;
  float: right;
  position: relative;
  bottom: 15px;
  color: rgb(21, 109, 121);
}

.name {
  font-size: 16px;
  font-weight: bold;
  color: rgb(21, 109, 121);
}

.desc {
  font-family: var(--third-font);

  color: black;

  font-size: 15px;
}
